import { Title } from "@/components/common/typography/Title";
import tarifs from "@/assets/images/homepage/tarifs.webp";
import Image from "next/image";
import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";

export const PricingSection = () => {
  return (
    <section className="relative flex flex-col gap-12 bg-secondary px-4 pt-20 ph:px-24 md:px-48 lg:px-72 lg:py-20 xl:px-96">
      <div className="flex flex-col gap-8 lg:flex-row lg:items-start lg:justify-between">
        <div className="z-10 flex flex-col gap-6 lg:w-1/2">
          <Title text="TARIFS" position="left" />
          <p className="text-sm text-gray-700 md:text-base lg:text-lg">
            Découvrez nos formules adaptées à tous les niveaux et tous les âges.
            Nous proposons des tarifs préférentiels pour les familles et les
            étudiants, ainsi que différentes options d&apos;abonnement : cours à
            l&apos;unité, forfaits trimestriels ou annuels. Nos tarifs incluent
            la licence fédérale, l&apos;assurance, et l&apos;accès à tous nos
            équipements. Possibilité de faire un cours d&apos;essai gratuit pour
            découvrir notre discipline et notre méthode d&apos;enseignement.
          </p>
          <div className="mt-4 w-fit">
            <PrimaryButton
              text="VOIR LES TARIFS"
              navigateTo="/tarifs"
              variant="primary"
            />
          </div>
        </div>

        <div className="relative lg:absolute lg:bottom-0 lg:right-0 lg:w-1/2">
          <div className="relative h-[300px] w-full md:h-[400px]">
            <Image
              src={tarifs}
              alt="Tarifs illustration"
              fill
              loading="lazy"
              className="object-contain mix-blend-multiply"
              sizes="(max-width: 1024px) 100vw, 50vw"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
