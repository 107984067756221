import { lessonsData } from "@/data/lessons";
import { Title } from "@/components/common/typography/Title";
import { useState } from "react";
import { MotionDiv } from "@/components/ui/MotionDiv";
import { MdiClockOutline } from "@/assets/icons/mdi-clock-outline";
import { MdiMapMarkerOutline } from "@/assets/icons/mdi-map-marker-outline";

type Category =
  | "Baby & Enfants"
  | "Ados & Adultes"
  | "Seniors"
  | "Féminin"
  | "Body & Combat";

export const LessonsHoursSection = () => {
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([
    "Baby & Enfants",
  ]);

  const toggleCategory = (category: Category) => {
    setSelectedCategories((prev) => {
      if (prev.length === 1 && prev.includes(category)) {
        return prev;
      }

      if (prev.includes(category)) {
        return prev.filter((c) => c !== category);
      }
      return [...prev, category];
    });
  };

  const filteredLessons = lessonsData.filter((lesson) =>
    lesson.categories.some((category) => selectedCategories.includes(category)),
  );

  const isCategorySelected = (category: Category) =>
    selectedCategories.includes(category);

  return (
    <section className="flex flex-col gap-12 bg-primary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <Title
        text="Horaires des cours"
        position="center"
        color="secondary"
        textPosition="center"
        className="hidden sm:flex"
      />
      <Title
        text={["Horaires ", "des cours"]}
        textPosition="center"
        position="center"
        color="secondary"
        className="flex sm:hidden"
      />

      {/* Categories Navigation */}
      <nav className="grid grid-cols-2 gap-x-8 gap-y-8 text-base sm:text-lg md:flex md:flex-wrap md:justify-start md:gap-12">
        {[
          "Baby & Enfants",
          "Ados & Adultes",
          "Body & Combat",
          "Féminin",
          "Seniors",
        ].map((category) => (
          <div key={category} className="flex justify-start">
            <button
              onClick={() => toggleCategory(category as Category)}
              className="relative inline-block pb-1"
            >
              <span
                className={`${
                  isCategorySelected(category as Category)
                    ? "font-medium text-white"
                    : "text-white/70 hover:text-white"
                } relative whitespace-nowrap transition-colors`}
              >
                {category.toUpperCase()}
                {isCategorySelected(category as Category) && (
                  <MotionDiv
                    className="absolute -bottom-[3px] left-0 h-0.5 w-full bg-gradient-to-r from-[#F9F383] via-[#F14553] to-[#19477E]"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: 1 }}
                    transition={{
                      duration: 0.4,
                      ease: "easeOut",
                    }}
                  />
                )}
              </span>
            </button>
          </div>
        ))}
      </nav>

      {/* Lessons Grid */}
      <div className="grid grid-cols-1 gap-16 md:grid-cols-2 xl:grid-cols-3">
        {filteredLessons.map((lesson) => (
          <div
            key={lesson.id}
            className="flex flex-col gap-4 border-l border-zinc-700 pl-6"
          >
            <h3 className="line-clamp-2 min-h-[3rem] text-xl font-bold text-white">
              {lesson.title}
            </h3>

            {lesson.timeSlots.map((slot, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex items-center gap-2 text-white">
                  <MdiClockOutline className="h-4 w-4" />
                  <span>
                    {slot.day} {slot.startTime} à {slot.endTime}
                  </span>
                </div>
                <div className="flex items-center gap-2 text-zinc-400">
                  <MdiMapMarkerOutline className="h-4 w-4" />
                  <span>
                    {slot.address.additionalInfo
                      ? slot.address.additionalInfo
                      : `${slot.address.street}, ${slot.address.postalCode} ${slot.address.city}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};
