import { Title } from "@/components/common/typography/Title";
import { useInView } from "framer-motion";
import { useState, useEffect, useRef } from "react";

interface LocationProps {
  name: string;
  address: string;
  mapUrl: string;
}

const Location = ({ name, address, mapUrl }: LocationProps) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "100px" });

  useEffect(() => {
    if (isInView) {
      setIsMapLoaded(true);
    }
  }, [isInView]);

  return (
    <div className="flex w-full flex-col gap-4" ref={containerRef}>
      <div className="relative aspect-video w-full bg-gray-100">
        {isMapLoaded && (
          <iframe
            src={mapUrl}
            className="absolute inset-0 h-full w-full"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        )}
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-xl font-bold text-white">{name}</h3>
        <p className="text-white">{address}</p>
      </div>
    </div>
  );
};

export const FindUsSection = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, margin: "100px" });

  return (
    <section
      ref={sectionRef}
      className="flex flex-col gap-12 bg-primary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96"
    >
      <div className="hidden sm:block">
        <Title
          text="Où nous trouver"
          textPosition="center"
          position="center"
          color="secondary"
        />
      </div>
      <div className="hidden xs:block sm:hidden">
        <Title
          text={["Où nous", "trouver"]}
          position="center"
          textPosition="center"
          color="secondary"
        />
      </div>
      <div className="block xs:hidden">
        <Title
          text={["Où ", "nous ", "trouver"]}
          position="center"
          textPosition="center"
          color="secondary"
        />
      </div>

      {isInView && (
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <Location
            name="GYMNASE PAUL VAILLANT COUTURIER 1"
            address="1 rue Grégoire Collas 95100 ARGENTEUIL"
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2620.476309226786!2d2.2464110767599084!3d48.94441569464831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6667a1c208b03%3A0xfbaac5868b9247c4!2sTaekwondo%20MUDO%20CLUB!5e0!3m2!1sfr!2sfr!4v1734919780949!5m2!1sfr!2sfr"
          />
          <Location
            name="HALLE DES SPORTS ROGER OUVRARD (SALLE DU DOJO)"
            address="6 All. Romain Rolland 95100 ARGENTEUIL"
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1101.5687495376296!2d2.232036267866811!3d48.95373741193702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6668e8f6c06b9%3A0x19adfcb3a8f6dec!2sHalle%20des%20Sports%20Roger%20Ouvrard!5e0!3m2!1sfr!2sfr!4v1734919854319!5m2!1sfr!2sfr"
          />
        </div>
      )}
    </section>
  );
};
