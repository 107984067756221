import { Title } from "@/components/common/typography/Title";
import { Image } from "@nextui-org/image";
import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";
// import whoweare1 from "@/assets/images/homepage/whoweare1.webp";
// import whoweare2 from "@/assets/images/homepage/whoweare2.webp";
// import whoweare3 from "@/assets/images/homepage/whoweare3.webp";
import logofftda from "@/assets/images/homepage/logo-fftda.webp";

import whoweare1 from "/public/images/events/telethon/telethon-24/photo3.webp";
import whoweare2 from "/public/images/events/championnat/championnat95-24/photo3.webp";
import whoweare3 from "/public/images/events/championnat/championnat-technique-18/photo3.webp";

export const WhoWeAreSection = () => {
  const images = [whoweare1, whoweare2, whoweare3];

  return (
    <section className="flex flex-col bg-secondary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96">
      <Title
        text="Qui sommes-nous"
        position="left"
        textPosition="left"
        color="primary"
        className="hidden sm:flex"
      />
      <Title
        text={["Qui ", "sommes-nous"]}
        position="left"
        textPosition="left"
        color="primary"
        className="hidden xs:flex sm:hidden"
      />
      <Title
        text={["Qui ", "sommes", "-nous"]}
        position="left"
        textPosition="left"
        color="primary"
        className="flex xs:hidden"
      />
      <div className="mt-12 flex w-full flex-col items-center gap-12">
        <p className="text-center text-primary">
          Depuis plus de vingt ans, le Mudo Club Argenteuil (Val d&apos;Oise)
          est bien plus qu&apos;une simple école de taekwondo. C&apos;est une
          grande famille, animée par des bénévoles passionnés qui transmettent
          leur amour pour cet art martial avec dévouement et expertise. Notre
          club, profondément ancré dans le tissu associatif d&apos;Argenteuil,
          ancré dans le tissu associatif d&apos;Argenteuil, s&apos;est construit
          sur des valeurs fortes de partage, de respect et de dépassement de
          soi. Grâce à l&apos;engagement sans faille de nos instructeurs
          bénévoles, nous offrons un enseignement de qualité accessible à tous,
          des plus jeunes aux plus âgés, dans une ambiance chaleureuse et
          familiale.
        </p>
      </div>

      <div className="w-full py-12">
        <div className="grid grid-cols-1 gap-8 ph:grid-cols-2 md:grid-cols-3">
          {images.map((image, index) => (
            <div
              key={index}
              className={`${index === 2 ? "hidden md:block" : ""}`}
            >
              <Image
                src={image.src}
                alt={`Image ${index + 1}`}
                loading="lazy"
                className="aspect-[2/3] w-full !rounded-none object-cover"
                radius="none"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-full flex-col items-center gap-12">
        <div>
          <PrimaryButton
            text="Voir la galerie"
            navigateTo="/galerie"
            variant="primary"
          />
        </div>

        <div className="mt-8 flex justify-center">
          <Image
            src={logofftda.src}
            alt="Logo FFTDA"
            loading="lazy"
            className="h-20 w-auto !rounded-none"
            radius="none"
          />
        </div>

        <p className="text-center text-sm italic text-primary/60">
          Mudo Club Argenteuil est affilié à la FFTDA (Fédération Française de
          Taekwondo et Disciplines Associées)
        </p>
      </div>
    </section>
  );
};
