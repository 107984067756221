"use client";

import useScrollRestoration from "@/utils/hooks/useScrollRestoration";
import { HeroSection } from "@/(landing-page)/sections/HeroSection";
import { BannerInformation } from "@/components/ui/BannerInformation";
import { BlogEventsSection } from "@/(landing-page)/sections/BlogEventsSection";
import { LessonsHoursSection } from "@/(landing-page)/sections/LessonsHoursSection";
import { WhoWeAreSection } from "@/(landing-page)/sections/WhoWeAreSection";
import { FindUsSection } from "@/components/sections/FindUsSection";
import { PricingSection } from "@/components/sections/PricingSection";

export default function HomePage() {
  useScrollRestoration();
  return (
    <main className="flex min-h-screen max-w-[100vw] flex-col">
      <HeroSection />
      <BannerInformation />
      <BlogEventsSection />
      <LessonsHoursSection />
      <WhoWeAreSection />
      <FindUsSection />
      <PricingSection />
    </main>
  );
}
