type DayOfWeek =
  | "Lundi"
  | "Mardi"
  | "Mercredi"
  | "Jeudi"
  | "Vendredi"
  | "Samedi"
  | "Dimanche";

type Category =
  | "Baby & Enfants"
  | "Ados & Adultes"
  | "Body & Combat"
  | "Féminin"
  | "Seniors";

type Duration = "45min" | "1h" | "1h30";

type Address = {
  street: string;
  city: string;
  postalCode: string;
  additionalInfo?: string;
};

type TimeSlot = {
  day: DayOfWeek;
  startTime: string;
  endTime: string;
  duration: Duration;
  address: Address;
};

type Lesson = {
  id: string;
  title: string;
  timeSlots: TimeSlot[];
  categories: Category[];
};

const ADDRESSES = {
  paulVaillant: {
    street: "1 impasse Grégoire Collas",
    city: "ARGENTEUIL",
    postalCode: "95100",
    additionalInfo: "Gymnase Paul Vaillant Couturier 1",
  },
  halleSports: {
    street: "6 allée Romain Rolland",
    city: "ARGENTEUIL",
    postalCode: "95100",
    additionalInfo: "Halle des sports Roger Ouvrard - Salle Dojo",
  },
  tourBilly: {
    street: "70 Rue de la Tour Billy",
    city: "ARGENTEUIL",
    postalCode: "95100",
    additionalInfo: "Rue de la Tour Billy - Salle des « Quatre vents »",
  },
} as const;

export const lessonsData: Lesson[] = [
  {
    id: "1",
    title: "Baby Taekwondo (3 à 4 ans)",
    timeSlots: [
      {
        day: "Samedi",
        startTime: "9:00",
        endTime: "9:45",
        duration: "45min",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Baby & Enfants"],
  },
  {
    id: "2",
    title: "Taekwondo (5 à 9 ans)",
    timeSlots: [
      {
        day: "Mercredi",
        startTime: "17:00",
        endTime: "18:00",
        duration: "1h",
        address: ADDRESSES.halleSports,
      },
      {
        day: "Samedi",
        startTime: "10:00",
        endTime: "10:45",
        duration: "45min",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Baby & Enfants"],
  },
  {
    id: "3",
    title: "Taekwondo (10 à 11 ans)",
    timeSlots: [
      {
        day: "Mardi",
        startTime: "20:00",
        endTime: "20:45",
        duration: "45min",
        address: ADDRESSES.paulVaillant,
      },
      {
        day: "Samedi",
        startTime: "11:00",
        endTime: "11:45",
        duration: "45min",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Baby & Enfants"],
  },
  {
    id: "4",
    title: "Taekwondo (Adolescents 12+ / Étudiants / Adultes)",
    timeSlots: [
      {
        day: "Mardi",
        startTime: "20:00",
        endTime: "21:30",
        duration: "1h30",
        address: ADDRESSES.paulVaillant,
      },
      {
        day: "Vendredi",
        startTime: "20:00",
        endTime: "21:30",
        duration: "1h30",
        address: ADDRESSES.paulVaillant,
      },
      {
        day: "Samedi",
        startTime: "12:00",
        endTime: "14:00",
        duration: "1h30",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Ados & Adultes"],
  },
  {
    id: "5",
    title: "Body Taekwondo",
    timeSlots: [
      {
        day: "Mardi",
        startTime: "20:00",
        endTime: "20:45",
        duration: "45min",
        address: ADDRESSES.paulVaillant,
      },
      {
        day: "Samedi",
        startTime: "12:00",
        endTime: "12:45",
        duration: "45min",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Body & Combat"],
  },
  {
    id: "6",
    title: "Combat Élite",
    timeSlots: [
      {
        day: "Jeudi",
        startTime: "20:00",
        endTime: "21:30",
        duration: "1h30",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Body & Combat"],
  },
  {
    id: "7",
    title: "Cours 100% Féminin",
    timeSlots: [
      {
        day: "Samedi",
        startTime: "11:00",
        endTime: "12:00",
        duration: "1h",
        address: ADDRESSES.paulVaillant,
      },
    ],
    categories: ["Féminin"],
  },
  {
    id: "8",
    title: "Séniors (60 ans et +)",
    timeSlots: [
      {
        day: "Vendredi",
        startTime: "17:00",
        endTime: "18:00",
        duration: "1h",
        address: ADDRESSES.tourBilly,
      },
    ],
    categories: ["Seniors"],
  },
];
