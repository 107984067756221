import Image from "next/image";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";
import { MiCalendar } from "@/assets/icons/mi-calendar";
import { MdiMapMarkerOutline } from "@/assets/icons/mdi-map-marker-outline";
import { StaticImageData } from "next/image";

interface BlogEventCardProps {
  id: string;
  title: string;
  description: string;
  date: string;
  location?: string;
  imageBanner: StaticImageData;
  eventLabel?: string;
  variant: "primary" | "secondary";
  type: "Blog" | "Event";
}

export const BlogEventCard = ({
  id,
  title,
  description,
  date,
  location,
  imageBanner,
  eventLabel,
  variant = "primary",
  type,
}: BlogEventCardProps) => {
  const cardStyles = {
    primary: {
      background: "bg-primary",
      text: "text-white",
      description: "text-gray-300",
      date: "text-gray-400",
    },
    secondary: {
      background: "bg-secondary",
      text: "text-primary",
      description: "text-gray-600",
      date: "text-gray-500",
    },
  };

  const styles = cardStyles[variant];

  return (
    <div
      className={`relative flex flex-col overflow-hidden ${styles.background} ${styles.text}`}
    >
      <div className="relative h-[200px] w-full overflow-hidden">
        <Image
          src={imageBanner}
          alt={title}
          fill
          loading="lazy"
          className="object-cover transition-transform duration-300"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
        {eventLabel && (
          <div className="absolute left-4 top-4 rounded bg-gradient-to-r from-yellow-400 to-red-500 px-4 py-1 text-white">
            {eventLabel.toUpperCase()}
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-col gap-4 p-6">
        <h3 className="line-clamp-2 h-[56px] text-xl font-bold">{title}</h3>
        <p className={`line-clamp-2 text-sm ${styles.description}`}>
          {description}
        </p>
        <div className={`flex flex-col gap-2 text-sm ${styles.date}`}>
          <div className="flex items-center gap-2">
            <MiCalendar className="h-4 w-4" />
            <span>{format(new Date(date), "dd/MM/yyyy", { locale: fr })}</span>
          </div>
          <div className="flex h-6 items-center gap-2">
            {location && (
              <>
                <MdiMapMarkerOutline className="h-4 w-4" />
                <span>{location}</span>
              </>
            )}
          </div>
        </div>
        <PrimaryButton
          navigateTo={`/blog-evenements/${id}`}
          text="EN SAVOIR PLUS"
          variant={variant === "primary" ? "secondary" : "primary"}
        />
      </div>
    </div>
  );
};
