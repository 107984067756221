import { Title } from "@/components/common/typography/Title";
import { blogsData } from "@/data/blog";
import { eventsData } from "@/data/events";
import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";
import { ChevronLeft } from "@/assets/icons/mdi-chevron-left";
import { ChevronRight } from "@/assets/icons/mdi-chevron-right";
import { useState, useEffect } from "react";
import { MotionDiv, AnimatePresence } from "@/components/ui/MotionDiv";
import { BlogEventCard } from "@/components/common/cards/BlogEventsCard";

type CombinedItem =
  | ((typeof blogsData)[0] & { type: "Blog" })
  | ((typeof eventsData)[0] & { type: "Event" });

export const BlogEventsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);
  const [isAutoScrollPaused, setIsAutoScrollPaused] = useState(false);

  const sortedEvents = eventsData
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 6)
    .map((event) => ({ ...event, type: "Event" as const }));

  const sortedBlogs = blogsData
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(0, 6)
    .map((blog) => ({ ...blog, type: "Blog" as const }));

  const combinedItems: CombinedItem[] = [
    ...sortedEvents.slice(0, 3),
    ...sortedBlogs,
    ...sortedEvents.slice(3, 6),
  ];

  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth >= 1024) setVisibleCount(3);
      else if (window.innerWidth >= 768) setVisibleCount(2);
      else setVisibleCount(1);
    };

    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  useEffect(() => {
    if (!isAutoScrollPaused) {
      const timer = setInterval(() => {
        handleNext(true);
      }, 7000);

      return () => clearInterval(timer);
    }
  }, [currentIndex, isAutoScrollPaused]);

  const handlePrevious = () => {
    setIsAutoScrollPaused(true);
    setDirection(-1);
    setCurrentIndex((prev) => {
      const newIndex = prev - visibleCount;
      if (newIndex < 0) {
        const lastPossibleIndex =
          Math.floor((combinedItems.length - 1) / visibleCount) * visibleCount;
        return lastPossibleIndex;
      }
      return newIndex;
    });
    setTimeout(() => setIsAutoScrollPaused(false), 5000);
  };

  const handleNext = (isAuto = false) => {
    if (!isAuto) {
      setIsAutoScrollPaused(true);
      setTimeout(() => setIsAutoScrollPaused(false), 5000);
    }
    setDirection(1);
    setCurrentIndex((prev) => {
      const newIndex = prev + visibleCount;
      return newIndex >= combinedItems.length ? 0 : newIndex;
    });
  };

  // Calcule les éléments visibles de manière cyclique
  const getVisibleItems = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      const index = (currentIndex + i) % combinedItems.length;
      items.push(combinedItems[index]);
    }
    return items;
  };

  const itemsToDisplay = getVisibleItems();

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 500 : -500,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 500 : -500,
      opacity: 0,
    }),
  };

  return (
    <section
      className="flex flex-col gap-12 bg-secondary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      onMouseEnter={() => setIsAutoScrollPaused(true)}
      onMouseLeave={() => setIsAutoScrollPaused(false)}
    >
      <Title
        text="Blog et événements"
        position="left"
        color="primary"
        className="hidden sm:flex"
      />
      <Title
        text={["Blog et", "événements"]}
        position="left"
        color="primary"
        className="flex sm:hidden"
      />
      <div className="relative overflow-hidden">
        <AnimatePresence initial={false} mode="wait" custom={direction}>
          <MotionDiv
            key={currentIndex}
            className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3"
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            {itemsToDisplay.map((item) => (
              <div key={item.id}>
                <BlogEventCard {...item} variant="primary" type={item.type} />
              </div>
            ))}
          </MotionDiv>
        </AnimatePresence>
      </div>
      <div className="relative flex flex-col items-start gap-8 lg:items-center">
        <div>
          <PrimaryButton
            navigateTo="/blog-evenements"
            text="TOUT VOIR"
            variant="primary"
          />
        </div>
        <div className="absolute right-0 flex gap-2">
          <MotionDiv
            onClick={handlePrevious}
            className="border border-gray-300 bg-white p-2 transition-colors hover:bg-gray-200"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Page précédente"
          >
            <ChevronLeft className="h-6 w-6 text-primary" />
          </MotionDiv>
          <MotionDiv
            onClick={() => handleNext(false)}
            className="border border-gray-300 bg-white p-2 transition-colors hover:bg-gray-200"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Page suivante"
          >
            <ChevronRight className="h-6 w-6 text-primary" />
          </MotionDiv>
        </div>
      </div>
    </section>
  );
};
