import { PrimaryButton } from "@/components/common/buttons/PrimaryButton";

export const HeroSection = () => {
  return (
    <section
      id="hero-section"
      className="flex min-h-[100dvh] w-full flex-col justify-start gap-32 overflow-x-hidden bg-cover bg-center bg-no-repeat px-4 py-[calc(112px+80px)] ph:justify-center ph:px-24 md:px-48 lg:px-72 xl:px-96"
    >
      <div className="flex w-2/3 flex-col gap-12 ph:mt-12">
        <h1 className="font-syne text-3xl font-extrabold uppercase leading-[1.2] tracking-wider text-white ph:text-4xl xl:text-6xl">
          Libérez votre guerrier <br /> intérieur
        </h1>

        <div className="flex flex-col gap-8">
          <p className="text-lg uppercase text-white ph:w-2/3 lg:w-full xl:text-2xl">
            Le club est ouvert à tous, venez nous voir !
          </p>
          <PrimaryButton
            navigateTo="/inscription"
            text="S'inscrire"
            variant="secondary"
          />
        </div>
      </div>
    </section>
  );
};
